<template xmlns:v-on="" xmlns:v-bind="http://www.w3.org/1999/xhtml">

    <div class="col s12 no-padding">


            <h5 class="blue-text text-darken-2" v-if="!showCompleteForm">Customer Registration</h5>
            <div class="row vert-offset-bottom-0" v-if="!showCompleteForm">
                <div class="col s12 blue-border border-darken-3 light-blue lighten-5 font-10 padding-top-half padding-bottom-half">
                    Seems like you are not a registered client. Please provide us with your registration information to continue.
                </div>
            </div>



            <div class="row vert-offset-bottom-0" v-if="showCompleteForm">

                <div class="col s12 no-padding">
                    <div class="row col s12 m5 vert-offset-top-1">
                        <custom-select-component ref="country" v-model="selected_country" :options="countries"  title="Type to select your Country"></custom-select-component>
                    </div>
                    <div class="col s12 m7 vert-offset-bottom-half">
                        <input-component id="mobile" type="tel" v-model="mobile" label="Mobile Number" required="required" autocomplete="off" minlength="6" maxlength="15"></input-component>
                    </div>

                </div>

            </div>

        <div class="row vert-offset-bottom-0" v-if="showCompleteForm">

            <div class="col s12">
                <input-component label="Name" id="name" type="text"  v-model="name"  autocomplete="off"></input-component>
            </div>
        </div>

            <div class="row vert-offset-bottom-0">
                <div class="col s12">
                    <input-component label="ID/Passport Number" id="idNumber" type="text"  v-model="idNumber"  required="required" autocomplete="off"></input-component>
                </div>
            </div>

           <!-- <div class="row vert-offset-bottom-0" v-if="showCompleteForm">

                <div class="col s12">
                    <input-component helper="Optional" id="email" type="email" v-model="email" label="E-Mail Address"  autocomplete="off" maxlength="15"></input-component>
                </div>
            </div>-->

            <div class="row" v-if="showCompleteForm">

                <div class="col s12 no-padding">
                    <div class="col s12 m6">
                        <div class="row col s12 no-padding vert-offset-bottom-half">
                            <custom-select-component id="gender" v-model="selected_gender" :options="genders"  title="Type to select your Gender">
                            </custom-select-component>
                        </div>
                    </div>

                    <div class="col s12 m6">
                        <div class="row col s12 no-padding vert-offset-bottom-half">
                            <custom-select-component id="yob" v-model="selected_yob" :options="yobs"  title="Type to select your Year of Birth">
                            </custom-select-component>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row vert-offset-bottom-0">

                <div class="col s12 no-padding">
                    <div class="col s12 m6">
                        <input-component  id="pin" type="password" helper="Pin should be 5 digits only" v-model="pin" label="PIN" required="required"></input-component>
                    </div>
                    <div class="col s12 m6">
                        <input-component helper="Should match PIN" id="confirmed_pin" type="password" v-model="confirmed_pin" label="Confirm PIN" required="required"></input-component>
                    </div>
                </div>


            </div>

            <div class="row vert-offset-bottom-0">
                <div class="col s6 offset-s3 center">
                    <a class="btn btn-block homeboy-gold-text homeboy-charcoal-black  font-12" @click.prevent="confirmSaveCustomer">
                        Submit
                    </a>
                </div>
            </div>

    </div>


</template>

<script>
    import InputComponent from "@/components/materialize/InputComponent.vue"
    import CustomSelectComponent from "@/components/materialize/CustomSelectComponent.vue"
    import SelectComponent from "@/components/materialize/SelectComponent.vue"
    import {mapGetters,mapState} from "vuex";
    import { bus } from "../../main"

    export default{
        name:"AddCustomer",
        props:{
            idNum:String,
            showAllForm: {
                type:Boolean,
                default:false,
            },
            update:{
                type:Boolean,
                default:false,
            }
        },
        components:{
            InputComponent,SelectComponent,CustomSelectComponent        },
        created(){

            this.getCountries();
            bus.$off('confirmAction');
            bus.$on('confirmAction', (_data) => {
                if(_data == "confirmSaveCustomer")
                {
                    this.saveCustomer();
                }
                this.$store.dispatch('SET_ACTION', '');
            });
        },
        mounted(){
            this.selected_country = "BW";
        },
        data(){
            return{
                name:"",
                idNumber:"",
                pin: "",
                confirmed_pin:"",
                mobile:"",
                email:"",
                selected_gender:"",
                selected_yob:"",
                selected_country:""
            }
        },
        methods:{

            validateForm: function()
            {
                let _arrErrors = new Array();
                if(typeof this.name === "undefined" || this.name === null || this.name.length <= 0)
                {
                    _arrErrors.push("Invalid Name");
                }
                else if(this.name.length > 100)
                {
                    _arrErrors.push("Name too long, cannot exceed 100 characters");
                }

                if(typeof this.idNumber === "undefined" || this.idNumber === null || this.idNumber.length < 0)
                {
                    _arrErrors.push("Invalid ID Number");
                }
                else if(this.idNumber.length > 15)
                {
                    _arrErrors.push("ID number too long, cannot exceed 15 characters");
                }

                if(typeof this.pin === "undefined" || this.pin === null || this.pin.length <= 0)
                {
                    _arrErrors.push("Please enter a valid PIN");
                }
                else if(this.pin.length != 5)
                {
                    _arrErrors.push("Your PIN must be 5 digits only");
                }
                else if(!this.pin.isNumeric())
                {
                    _arrErrors.push("Your PIN must be 5 digits only");
                }
                else if(this.pin !== this.confirmed_pin)
                {
                    _arrErrors.push("Confirmation pin does not match new pin.")
                }

                if(typeof this.mobile === "undefined" || this.mobile === null || this.mobile.length <= 0)
                {
                    _arrErrors.push("Invalid Mobile Number");
                }
                else if(this.mobile.length > 15)
                {
                    _arrErrors.push("Mobile number too long, cannot exceed 15 characters");
                }

                if(typeof this.selected_country === "undefined" || this.selected_country === null || this.selected_country.length != 2)
                {
                    _arrErrors.push("Invalid Country");
                }
                if(_arrErrors.length > 0)
                {
                    this.$store.dispatch('GET_MESSAGES',_arrErrors);
                    return false;
                }

                return true;
            },
            saveCustomer:function ()
            {
                const customerData = {
                    idNum:this.idNumber,
                    pin:this.pin,
                    confirmed_pin:this.confirmed_pin,
                    mobile:this.mobile,
                    gender:this.selected_gender,
                    yob:this.selected_yob,
                    name:this.name,
                  /*  email:this.email,*/
                    country:this.selected_country,
                    source: this.$source
                };
                console.log("Customer Data: " + JSON.stringify(customerData));
                this.$emit('save',customerData);
            },
            confirmSaveCustomer: function()
            {
                console.log("Event Triggered: confirmSaveCustomer");
                if(this.validateForm())
                {
                    this.$store.dispatch('SET_ACTION', 'confirmSaveCustomer');
                }
            },

            getCountries:function () {
                this.$store.dispatch("GET_L2L_COUNTRIES_REQUEST");
            }

        },
        computed:{
            showCompleteForm:function()
            {
                return this.showAllForm;
            },
            ...mapGetters({
                genders:'gender',
                yobs:'year_of_birth',
            }),
            ...mapState({
                countries:state=>state.loyaltolocal.l2l_countries
            })
        }
    }

</script>