<template>
  <select :value="value">
    <option value="" disabled selected><slot></slot></option>
    <option v-for="opt in options" :key="opt.key" :value="opt.key">{{
      opt.name
    }}</option>
  </select>
</template>

<script>
import "materialize-css/dist/js/materialize.min";
import M from "materialize-css";

export default {
  name: "select-component",
  props: {
    message: {
      type: String
    },
    value: {
      type: [Number, String, Array],
      default: ""
    },
    options: {
      type: Array
    },
      selected:{String}
  },

  /**
   * @description Component local variables
   * @return {Object} data
   * @return {undefined|FormSelect} data.instance
   */
  data() {
    return {
      instance: undefined
    };
  },

  methods: {
    /**
     * @description Initialize a new Materialize select component
     * @param {Object} options
     * @return {FormSelect}
     * @see https://materializecss.com/select.html#options
     */
    initializeSelect(options = {}) {
      return M.FormSelect.init(this.$el, options);
    },

    /**
     * @description Send the proper input event to the parents components
     * @param {Event} event
     * @param {HTMLSelectElement} target
     * @see https://developer.mozilla.org/fr/docs/Web/API/Event/target
     */
    changeHandler({ target }) {
      console.log("Input Change: " + target.value);
      this.$emit("input", target.value);
    }
  },
  mounted() {
    console.log("component mounted");
    this.instance = this.initializeSelect();
    console.log(this.instance);
    this.$el.addEventListener("change", this.changeHandler);
  },
  created() {
    console.log("component created");
  },

  watch: {
    value() {
      console.log("value watch:");
      //this.instance.destroy();

      this.$nextTick(() => (this.instance = this.initializeSelect()));
    }
  },

  destroyed() {
    this.$el.removeEventListener("change", this.changeHandler);
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  .dropdown-content li>a,
  .dropdown-content li>span {
    color:#000 !important;
  }

</style>
