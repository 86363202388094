<template xmlns:v-on="http://www.w3.org/1999/xhtml">
    <div class="row left-align vert-offset-top-1">
        <div class="col s12 l10 offset-l1">
            <div class="card col s12 vert-offset-top-1 padding-top-half">

                <div class="col s12 card-title padding-top-half padding-bottom-half border-bottom-1 grey-border border-lighten-2 homeboy-font">
                    <router-link class="btn-small homeboy-charcoal-black white-text margin-right-half" to="/">
                        <i class="material-icons">arrow_back</i>
                    </router-link>

                    <span>
                            Register below or <router-link
                            class="homeboy-gold-text underline"
                            to="/login"
                    >
                        Login
                    </router-link>
                        </span>


                </div>
                <div class="card-content col s12 padding-left-1 padding-right-1 padding-top-1">
                    <div class="col s12">
                        <add-customer-component v-on:save="saveCustomer" :showAllForm="true"></add-customer-component>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>

    import AddCustomerComponent from "@/components/customers/AddCustomerComponent.vue"
    import http from "../../http-common"

    export default {
        name: 'Register-Customer',
        components:{
            AddCustomerComponent
        },
        data(){
            return{

            }
        },
        methods:{
            saveCustomer(data)
            {
                /*console.log("saving customer:"+data.pin);*/
                this.$store.dispatch('START_PROCESSING');
                return new Promise((resolve) => {
                    http({
                        url: 'l2l/customers/save',
                        method: 'POST',
                        data: {
                            idnumber : data.idNum,
                            msisdn:data.mobile,
                            pin:data.pin,
                            pin_confirmation:data.confirmed_pin,
                            country:data.country,
                            gender:data.gender,
                            yob:data.yob,
                            email:data.email,
                            name: data.name,
                            source: this.$source

                        }, timeout: 30000
                    })
                        .then(resp => {

                            this.$store.dispatch('STOP_PROCESSING');
                            this.$store.dispatch('GET_MESSAGES',["Your account has been successfully created. You will shortly receive an SMS from BrandBox containing your mobile verification code. Please login to 'Support a HomeBoy' and use this code to verify your number."]);

                            this.$router.push("/confirm/mobile");
                            //this.purchase();

                            resolve(resp)
                        })
                        .catch((response) => {
                            /*console.log("ErrResponse: "+JSON.stringify(response.data));*/
                            this.$store.dispatch('STOP_PROCESSING');
                            this.$store.dispatch('GET_MESSAGES',response.data.messages);
                            //reject(err)
                        })
                })

            },
        }
    }
</script>
